import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  FunctionField,
  NumberField,
  TopToolbar,
  ListButton,
  EditButton,
  ReferenceManyField,
  Datagrid,
  DeleteWithConfirmButton,
  useTranslate,
  Button,
  Pagination,
  DateField,
  BooleanField,
  ShowButton,
  useRedirect,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Grid, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormShow } from '../../styles';
import { DocumentDownloadButton, DocumentUploadButton } from '../Documents';
import { DocumentList } from '../Documents';
import { AuthRole, Company, DocumentSegment, State } from '../../Utils/enums';
import GuaranteeShow from '../Guarantee/GuaranteeShow';
import FormTitle from '../../Components/FormTitle';
import NotesIterator from '../Notes/NotesIterator';
import StateChangeButton from './StateChangeButton';
import Rating from '../Rating';
import TablePlaceholder from '../../Components/TablePlaceholder';
import LoanCalculator from '../../Components/LoanCalculator';
import FullNameField from '../../Components/FullNameField';
import DocumentValidateButton from './components/DocumentValidateButton';
import SubstateChangeButton from './SubstateChangeButton';
import VeredictButton from './VeredictButton';
import { Timeline } from '../../Components/Timeline';
import { References } from './components/References';
import { savePreviousPath } from '../../Utils/functions';

const LoanApplicationsShowActions = ({ role, basePath, data, permissions }) => {
  const validateEdit = () => {
    const isTreasury = role === AuthRole.Treasury;
    const isCAV = role === AuthRole.CAV;
    const isStateValid = [
      State.ReceiveDocumentation,
      State.ControlDesk,
      State.CreditAnalysis,
      State.Formalization,
      State.ReceiveDocumentationKap,
      State.ControlDeskKap,
      State.CreditAnalysisKap,
      State.FormalizationKap,
    ].includes(data?.state?.id);
    const isStateValidForCAV = [State.Disbursed, State.DisbursedKap].includes(
      data?.state?.id
    );
    const isStateValidForTreasury = [
      State.Signed,
      State.Disbursed,
      State.SignedKap,
      State.DisbursedKap,
    ].includes(data?.state?.id);
    const hasEditPermission =
      permissions?.['loan-application']?.update?.enabled;

    if (isCAV) return hasEditPermission && isStateValidForCAV;
    if (isTreasury) return hasEditPermission && isStateValidForTreasury;
    return role === AuthRole.Admin || (hasEditPermission && isStateValid);
  };

  return (
    <TopToolbar>
      {permissions?.document?.veredicto?.enabled && (
        <VeredictButton record={data} />
      )}
      {permissions?.['loan-application']?.assignsubstate?.enabled && (
        <SubstateChangeButton {...data} />
      )}
      <StateChangeButton record={data} />
      <ListButton
        basePath={basePath}
        label='ra.action.back'
        icon={<ChevronLeft />}
        component={Link}
        to={basePath}
      />
      {validateEdit() && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
  );
};

const styles = {
  button: {
    marginTop: '1em',
  },
};

const AddNewButton = withStyles(styles)(
  ({ classes, record, label, path }: any) => {
    return (
      <Button
        className={classes.button}
        component={Link}
        to={{
          pathname: `/${path}/create`,
          search: `?loanApplication=${record.id}&person=${record.person?.id}&legalPerson=${record.legalPerson?.id}`,
        }}
        label={label}
      >
        <AddCircleOutlineIcon />
      </Button>
    );
  }
);

const LoanApplicationShow = (props) => {
  const classes = FormShow();
  const translate = useTranslate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const role = user.role?.id;
  const tab = props.location.pathname.split('show/')[1];
  const company = user.company?.id || Company.IbanOnlineMx;

  const redirect = useRedirect();

  return (
    <Show
      {...props}
      actions={<LoanApplicationsShowActions {...props} role={role} />}
      aside={
        tab !== 'calculator' && tab !== 'guarantees' ? (
          <Timeline segment='loanApplication' />
        ) : (
          <></>
        )
      }
      classes={{ card: classes.show }}
    >
      <TabbedShowLayout className={classes.borderBottom}>
        <Tab label='General' contentClassName={classes.flexParent}>
          <FormTitle title='Información' className={classes.width100} />
          <TextField
            source='state.name'
            emptyText='-'
            className={classes.width100}
          />
          <TextField source='id' emptyText='-' className={classes.width25} />
          <FunctionField
            source='agent'
            className={classes.width25}
            render={(record) =>
              record?.agent
                ? `${record.agent.code} - ${record.agent.person?.firstName} ${record.agent.person?.fatherLastName}`
                : '-'
            }
          />
          <FunctionField
            source='referrer.code'
            className={classes.width25}
            render={(record) =>
              record?.referrer
                ? `${record.referrer.code} - ${record.referrer.name}`
                : '-'
            }
          />
          <TextField
            source='referrerCode'
            emptyText='-'
            className={classes.width25}
          />
          {role !== AuthRole.HumanResource && (
            <FunctionField
              source='person'
              className={classes.width25}
              render={(record) => (
                <>
                  {record?.person ? (
                    <ReferenceField
                      source='person.id'
                      emptyText='-'
                      link='show'
                      resource='people'
                      reference='people'
                    >
                      <FunctionField
                        className={classes.textColor}
                        render={(record) =>
                          record &&
                          `${record?.firstName} ${record?.secondName ?? ''} ${
                            record?.fatherLastName
                          } ${record?.motherLastName ?? ''}`
                        }
                      />
                    </ReferenceField>
                  ) : (
                    <ReferenceField
                      source='legalPerson.id'
                      emptyText='-'
                      link='show'
                      resource='legal-people'
                      reference='legal-people'
                      className={classes.width25}
                    >
                      <FunctionField
                        className={classes.textColor}
                        render={(record) =>
                          record &&
                          `${record.documentNumber} - ${record.businessName}`
                        }
                      />
                    </ReferenceField>
                  )}
                </>
              )}
            />
          )}
          <NumberField
            source='amount'
            className={classes.width25}
            options={{ style: 'currency', currency: 'MXN' }}
          />
          <NumberField
            source='months'
            emptyText='-'
            className={classes.width25}
          />
          <FunctionField
            source='interestRate'
            emptyText='-'
            className={classes.width25}
            render={(record) =>
              record?.interestRate ? `${record.interestRate}%` : '-'
            }
          />
          <TextField
            source='applicationType.name'
            className={classes.width25}
            emptyText='-'
          />
          <TextField
            source='contractNumber'
            emptyText='-'
            className={classes.width25}
          />
          <TextField
            source='evaluationUnit.name'
            className={classes.width25}
            emptyText='-'
          />
          <TextField
            source='notary.name'
            className={classes.width25}
            emptyText='-'
          />
          <NumberField
            source='appraisedValue'
            className={classes.width25}
            emptyText='-'
            options={{ style: 'currency', currency: 'MXN' }}
          />
          <TextField
            source='clientSource.name'
            emptyText='-'
            className={classes.width25}
          />
          {company === Company.IbanOnlineMx && (
            <TextField
              source='applicationReason.name'
              emptyText='-'
              className={classes.width25}
            />
          )}
          <TextField
            source='applicationSource.name'
            emptyText='-'
            className={classes.width25}
          />
          <TextField
            source='fundingCompany.name'
            emptyText='-'
            className={classes.width25}
          />
          <FormTitle title='Core Banking' className={classes.width100} />
          <TextField
            source='cbLoan'
            className={classes.width25}
            emptyText='-'
          />
          <DateField
            source='cbApprovalDate'
            className={classes.width25}
            emptyText='-'
            locales='es-MX'
          />
        </Tab>
        <Tab label='Notas' path='notes'>
          <ReferenceManyField
            target='loanApplication'
            reference='contact-histories'
            addLabel={false}
            className={classes.width100}
            filter={{ segment: 'loanApplication', related: props.id }}
            pagination={<Pagination />}
          >
            <NotesIterator
              showStatus
              reference='loan-applications'
              segment='loanApplication'
              related={props.id}
            />
          </ReferenceManyField>
        </Tab>
        <Tab label='Garantías' path='guarantees'>
          <AddNewButton label='Agregar Garantía' path='guarantees' />
          <ReferenceManyField
            className={classes.width100}
            reference='guarantees'
            addLabel={false}
            target='loanApplication'
            filter={{ loanApplication: props.id }}
          >
            <Datagrid
              empty={<TablePlaceholder title='Garantías' />}
              expand={<GuaranteeShow />}
            >
              <TextField emptyText='-' source='asset.documentNumber' />
              <NumberField
                emptyText='-'
                source='asset.value'
                options={{ style: 'currency', currency: 'MXN' }}
              />
              <BooleanField source='asset.legalOpposition' />
              <FunctionField
                source='owner'
                render={(record) =>
                  record?.asset?.person ? (
                    <FullNameField
                      record={record?.asset}
                      entity='person'
                      source='owner'
                      size={24}
                    />
                  ) : (
                    <TextField
                      emptyText='-'
                      source='asset.legalPerson.businessName'
                    />
                  )
                }
              />
              <DateField source='created_at' locales='es-MX' />
              <FunctionField
                render={(record) => (
                  <>
                    <ShowButton
                      label=''
                      onClick={() => {
                        savePreviousPath(window.location.href);
                        redirect(`/assets/${record?.asset?.id}/show`);
                      }}
                    />
                    {![State.Disbursed, State.DisbursedKap].includes(
                      record?.loanApplication?.state
                    ) && (
                      <DeleteWithConfirmButton
                        record={record}
                        redirect={false}
                        label=''
                        confirmContent={translate(
                          'resources.guarantees.fields.confirmDelete'
                        )}
                        confirmTitle='Eliminar'
                      />
                    )}
                  </>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label='Documentos' path='documents'>
          <Grid container style={{ flexDirection: 'row-reverse', gap: '8px' }}>
            <Grid item>
              <DocumentUploadButton
                documentSegment={DocumentSegment.Application.id}
                segment={DocumentSegment.Application.name}
                related={props.id}
              />
            </Grid>
            <Grid item>
              {role === AuthRole.Formalization && (
                <DocumentDownloadButton related={props.id} />
              )}
            </Grid>
            <Grid item>
              {[
                AuthRole.Business,
                AuthRole.BusinessKap,
                AuthRole.Admin,
              ].includes(role) && <DocumentValidateButton related={props.id} />}
            </Grid>
          </Grid>
          <DocumentList segment={DocumentSegment.Application.name} />
        </Tab>
        {[AuthRole.ControlDesk, AuthRole.Risk].includes(role) && (
          <Tab label='Documentos Privados' path='private-documents'>
            <Grid
              container
              style={{ flexDirection: 'row-reverse', gap: '8px' }}
            >
              <Grid item>
                <DocumentUploadButton
                  documentSegment={DocumentSegment.Application.id}
                  segment={DocumentSegment.Application.name}
                  related={props.id}
                  reserved
                />
              </Grid>
            </Grid>
            <DocumentList segment={DocumentSegment.Application.name} private />
          </Tab>
        )}
        <Tab label='Rating' path='rating'>
          <Rating record={props} />
        </Tab>
        <Tab label='Calculadora' path='calculator'>
          <LoanCalculator record={props} />
        </Tab>
        <Tab label='Referencias' path='references'>
          <References {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LoanApplicationShow;
