import React from 'react';
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceManyField,
  useTranslate,
  ReferenceField,
} from 'react-admin';
import { Grid } from '@material-ui/core';

import { convertTheValueToBytes } from '../../Utils/convertTheValueToBytes';
import { FormGeneral } from '../../styles';
import { DocumentType, State } from '../../Utils/enums';
import TablePlaceholder from '../../Components/TablePlaceholder';
import DocumentIconNameField from './DocumentIconNameField';
import DocumentViewButton from './DocumentViewButton';
import DocumentDeleteButton from './DocumentDeleteButton';
import DocumentEditButton from './DocumentEditButton';

const DocumentList = (props) => {
  const classes = FormGeneral();
  const translate = useTranslate();
  const { id } = props.record;
  const segment = props.segment;
  const reserved = Boolean(props.private);
  const filter = {
    segment,
    related: id,
  };

  filter[reserved ? 'private' : 'private_ne'] = true;

  return (
    <ReferenceManyField
      reference='documents'
      target='person'
      filter={filter}
      sort={{ field: 'archived', order: 'ASC' }}
      perPage={-1}
      addLabel={false}
      className={classes.width100}
    >
      <Datagrid
        empty={<TablePlaceholder title='Documentos' />}
        rowStyle={(record) => ({
          opacity: record.archived ? 0.4 : 1,
        })}
      >
        <DocumentIconNameField
          source='documents[0].name'
          emptyText='-'
          label={translate('resources.documents.fields.name')}
          sortable={false}
        />
        <ReferenceField
          source='documentType'
          resource='people'
          reference='document-types'
          className={classes.width25}
          link={false}
          emptyText='-'
          label={translate('resources.documents.fields.documentType')}
        >
          <ChipField source='name' />
        </ReferenceField>
        <FunctionField
          render={(record) =>
            convertTheValueToBytes(record?.documents[0]?.size)
          }
          source='size'
          label={translate('resources.documents.fields.size')}
          emptyText='-'
          sortable={false}
        />
        <DateField
          source='created_at'
          showTime={false}
          emptyText='-'
          locales='es-MX'
          label={translate('resources.documents.fields.created_at')}
        />
        <FunctionField
          render={(record) => (
            <Grid container>
              <Grid item xs={4}>
                <DocumentViewButton record={record} />
              </Grid>
              {record.documentType !== DocumentType.CreditReport &&
                !record.archived && (
                  <Grid item xs={4}>
                    <DocumentEditButton record={record} />
                  </Grid>
                )}
              {record.documentType !== DocumentType.CreditReport &&
                [
                  State.ReceiveDocumentation,
                  State.ControlDesk,
                  State.CreditAnalysis,
                ].includes(props?.record?.state?.id) && (
                  <Grid item xs={4}>
                    <DocumentDeleteButton record={record} />
                  </Grid>
                )}
            </Grid>
          )}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default DocumentList;
